export const taxIds = {
    'United Arab Emirates': {
        type: 'ae_trn',
        codePrefix: '',
        example: '123456789012345',
    },
    Australia: {
        type: 'au_abn',
        codePrefix: '',
        example: '12345678912',
    },
    Brazil: {
        type: 'br_cpf',
        codePrefix: '',
        example: '01.234.456/5432-10',
    },
    Canada: {
        type: 'ca_bn',
        codePrefix: '',
        example: '123456789',
    },
    Switzerland: {
        type: 'ch_vat',
        codePrefix: '',
        example: 'CHE-123.456.789 MWST',
    },
    Chile: {
        type: 'cl_tin',
        codePrefix: '',
        example: '12.345.678-K',
    },
    Austria: {
        type: 'eu_vat',
        codePrefix: 'AT',
        example: 'U12345678',
    },
    Belgium: {
        type: 'eu_vat',
        codePrefix: 'BE',
        example: '0123456789',
    },
    Bulgaria: {
        type: 'eu_vat',
        codePrefix: 'BG',
        example: '0123456789',
    },
    Cyprus: {
        type: 'eu_vat',
        codePrefix: 'CY',
        example: '12345678Z',
    },
    'Czech Republic': {
        type: 'eu_vat',
        codePrefix: 'CZ',
        example: '1234567890',
    },
    Germany: {
        type: 'eu_vat',
        codePrefix: 'DE',
        example: '123456789',
    },
    Denmark: {
        type: 'eu_vat',
        codePrefix: 'DK',
        example: '12345678',
    },
    Estonia: {
        type: 'eu_vat',
        codePrefix: 'EE',
        example: '123456789',
    },
    Spain: {
        type: 'eu_vat',
        codePrefix: 'ES',
        example: 'A1234567Z',
    },
    Finland: {
        type: 'eu_vat',
        codePrefix: 'FI',
        example: '12345678',
    },
    France: {
        type: 'eu_vat',
        codePrefix: 'FR',
        example: 'AB123456789',
    },
    'United Kingdom': {
        type: 'eu_vat',
        codePrefix: 'GB',
        example: '123456789',
    },
    Greece: {
        type: 'eu_vat',
        codePrefix: 'EL',
        example: '123456789',
    },
    Croatia: {
        type: 'eu_vat',
        codePrefix: 'HR',
        example: '12345678912',
    },
    Hungary: {
        type: 'eu_vat',
        codePrefix: 'HU',
        example: '12345678912',
    },
    Ireland: {
        type: 'eu_vat',
        codePrefix: 'IE',
        example: '1234567AB',
    },
    Italy: {
        type: 'eu_vat',
        codePrefix: 'IT',
        example: '12345678912',
    },
    Lithuania: {
        type: 'eu_vat',
        codePrefix: 'LT',
        example: '123456789123',
    },
    Luxembourg: {
        type: 'eu_vat',
        codePrefix: 'LU',
        example: '12345678',
    },
    Latvia: {
        type: 'eu_vat',
        codePrefix: 'LV',
        example: '12345678912',
    },
    Malta: {
        type: 'eu_vat',
        codePrefix: 'MT',
        example: '12345678',
    },
    Netherlands: {
        type: 'eu_vat',
        codePrefix: 'NL',
        example: '123456789B12',
    },
    Poland: {
        type: 'eu_vat',
        codePrefix: 'PL',
        example: '1234567890',
    },
    Portugal: {
        type: 'eu_vat',
        codePrefix: 'PT',
        example: '123456789',
    },
    Romania: {
        type: 'eu_vat',
        codePrefix: 'RO',
        example: '1234567891',
    },
    Sweden: {
        type: 'eu_vat',
        codePrefix: 'SE',
        example: '123456789123',
    },
    Slovenia: {
        type: 'eu_vat',
        codePrefix: 'SI',
        example: '12345678',
    },
    Slovakia: {
        type: 'eu_vat',
        codePrefix: 'SK',
        example: '1234567891',
    },
    'Hong Kong': {
        type: 'hk_br',
        codePrefix: '',
        example: '12345678',
    },
    Indonesia: {
        type: 'id_npwp',
        codePrefix: '',
        example: '12.345.678.9-012.345',
    },
    India: {
        type: 'in_gst',
        codePrefix: '',
        example: '12ABCDE3456FGZH',
    },
    Japan: {
        type: 'jp_cn',
        codePrefix: '',
        example: '1234567891234',
    },
    "Korea, Democratic People's Republic of": {
        type: 'kr_brn',
        codePrefix: '',
        example: '123-45-67890',
    },
    Liechtenstein: {
        type: 'li_uid',
        codePrefix: '',
        example: 'CHE123456789',
    },
    Mexico: {
        type: 'mx_rfc',
        codePrefix: '',
        example: 'ABC010203AB9',
    },
    Malaysia: {
        type: 'my_frp',
        codePrefix: '',
        example: '12345678',
    },
    Norway: {
        type: 'no_vat',
        codePrefix: '',
        example: '123456789MVA',
    },
    'New Zealand': {
        type: 'nz_gst',
        codePrefix: '',
        example: '123456789',
    },
    'Russian Federation': {
        type: 'ru_inn',
        codePrefix: '',
        example: '1234567891',
    },
    'Saudi Arabia': {
        type: 'sa_vat',
        codePrefix: '',
        example: '123456789012345',
    },
    Singapore: {
        type: 'sg_gst',
        codePrefix: '',
        example: 'M12345678X',
    },
    Thailand: {
        type: 'th_vat',
        codePrefix: '',
        example: '1234567891234',
    },
    Taiwan: {
        type: 'tw_vat',
        codePrefix: '',
        example: '12345678',
    },
    'United States': {
        type: 'us_ein',
        codePrefix: '',
        example: '12-3456789',
    },
    'South Africa': {
        type: 'za_vat',
        codePrefix: '',
        example: '4123456789',
    },
}
